import axios from 'axios';
import { useEffect, useState } from 'react';
import logo from '../../assets/image/logo.jpg';
import qrcode from '../../assets/image/qrcode.png'
import './index.css';
import {json} from "react-router-dom";
const DepartmentMap = {
  24: '学府店',
  25: '新北店',
  26: '盛世阳光店',
  27: '水岸新城店',
  28: '西山精选店',
  29: '墨金苑店',
};
const ListPage = () => {
  const [list, setList] = useState([]);
  const remLayout = () => {
    let rootDoc = document.documentElement,
      resizeEvent =
        'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = () => {
        let clientWidth = Math.max(rootDoc.clientWidth, window.innerWidth);
        if (!clientWidth) return;
        rootDoc.style.fontSize = 20 * (clientWidth / 750) + 'px';
      };
    recalc();
    window.addEventListener(resizeEvent, recalc, !1);
    window.addEventListener('DOMContentLoaded', recalc, !1);
  };
  useEffect(() => {
    remLayout();
    fetchData();
  }, []);
  const fetchData = async () => {
    const res = await axios.get('http://hhcs0477.com:89/index.php?s=super-logs');
    setList(res.data.data);
  };
  const getImg = (value) =>
  {
    const imgs = JSON.parse(value)
    if (imgs.length>=1) {
      if (imgs instanceof Array) {
        return imgs
      }
    }
    return []
  }
  return (
    <div className="list">
      <div className="list-header">
        <div className="list-header-left">
          <img src={logo} alt="" className="logo" />
          <div className="title">和合超市顾客意见公示台</div>
        </div>
        <div>
          <img src={qrcode} alt="" style={{width:"70px",height:"70px",marginTop:"-29px"}} className="logo" />
        </div>
      </div>
      {list.map((item, index) => (
          <div key={index} className="list-content">
            <span className="iconfont handleIcon">&#xe631;</span>
            <div className="time" style={{fontSize: '20px'}}>
              <span className="iconfont timer">&#xe89e;</span> {item.create_time}
            </div>
            <div style={{ marginBottom: "0.3rem", color: "grey",fontSize: '20px' }}>
              来源：微信
            </div>
            <div style={{ marginBottom: "0.3rem", color: "grey",fontSize: '20px' }}>
              类型：{item.type}
            </div>
            <div style={{ marginBottom: "0.3rem", color: "grey",fontSize: '20px' }}>
              所选门店：{DepartmentMap[+item.department]}
            </div>
            <div>
              <p style={{fontSize: '20px'}}>{item.content}</p>
              {JSON.parse(item.img).map((val) => (
                  <img src={decodeURI(val)} alt="" className="response-img" />
              ))}
            </div>
            <div className="response">
              <div className="response-text" style={{fontSize: '20px'}}>
                <span className="iconfont">&#xe638;</span>处理结果
              </div>
              <div style={{ marginBottom: "0.3rem", color: "grey",fontSize: '20px' }}>
                回复时间：{item.update_time}
              </div>
              <div style={{ marginBottom: "0.3rem", color: "grey",fontSize: '20px' }}>
                处理部门：{item.response_department}
              </div>
              <div style={{ marginBottom: '0.3rem', color: 'grey',fontSize: '20px' }}>
                处理结果：{item.response}
              </div>
              <div style={{position:"relative"}}>
                <div style={{ marginBottom: '0.3rem', color: 'grey',fontSize: '20px' }}>
                  回复图片：
                </div>
                {getImg(item.response_img).map((val) => (
                    <img src={decodeURI(val)} alt="" className="response-img" />
                ))}
              </div>
            </div>
          </div>
      ))}
    </div>
  );
};
export default ListPage;
