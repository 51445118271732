import { useEffect } from "react";
import { Form, Button, Input, Picker, Uploader, Toast } from "react-vant";
import "./index.css";
import axios from "axios";
import { useNavigate } from "react-router";
import qrcode from '../../assets/image/qrcode.png'
import {UploaderValueItem} from "react-vant/es/uploader/PropsType";
const columns = [
  {
    value: "24",
    text: "学府店",
  },
  {
    value: "25",
    text: "新北店",
  },
  {
    value: "26",
    text: "盛世阳光店",
  },
  {
    text: "水岸新城店",
    value: "27",
  },
  {
    text: "西山精选店",
    value: "29",
  },
  {
    text: "墨金苑店",
    value: "28",
  },
    {
        text: "金鹭店",
        value: "39",
    },
];
const FormPage = () => {
  const navigate = useNavigate();
  const remLayout = () => {
    let rootDoc = document.documentElement,
      resizeEvent =
        "orientationchange" in window ? "orientationchange" : "resize",
      recalc = () => {
        let clientWidth = Math.max(rootDoc.clientWidth, window.innerWidth);
        if (!clientWidth) return;
        rootDoc.style.fontSize = 20 * (clientWidth / 750) + "px";
      };
    recalc();
    window.addEventListener(resizeEvent, recalc, !1);
    window.addEventListener("DOMContentLoaded", recalc, !1);
  };
  const [form] = Form.useForm();

  useEffect(() => {
    remLayout();
  }, []);
  const onFinish = async (val) => {
      const img = []
      val.img.forEach((item)=>{
          img.push(item.url)
      })

    const res = await axios.post(
      `http://hhcs0477.com:89/index.php?s=complaint`,
      {
        ...val,
        img: img,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (res.data.errno === 0) {
      Toast.success("提交成功");
      navigate("/list");
    }
  };

  return (
    <div className="form-container">
      <h2
        className="title-form"
        style={{ textAlign: "center", color: "#3f45ff" }}
      >
        意见建议
      </h2>
      <h2
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: "1rem",
          color: "#3f45ff",
        }}
      >
        <img
          src={JSON.parse(localStorage.getItem("user"))?.imgUrl}
          alt=""
          style={{
            width: "3rem",
            borderRadius: "50%",
            verticalAlign: "middle",
            marginRight: "1rem",
          }}
        />
        亲爱的，{JSON.parse(localStorage.getItem("user"))?.nickName}
      </h2>
      <Form
        onFinish={onFinish}
        layout="vertical"
        footer={
          <div style={{ margin: "16px 16px 80px 10px" }}>
            <Button round nativeType="submit" type="primary" block>
              提交
            </Button>
          </div>
        }
      >
        <Form.Item
          name="name"
          label="顾客姓名"
          rules={[{ required: true, message: "顾客姓名不能为空" }]}
        >
          <Input placeholder="请输入顾客姓名" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="顾客联系电话"
          rules={[
            { required: true, message: "顾客联系电话不能为空" },
            {
              validator: (_, value) => {
                if (/1\d{10}/.test(value)) {
                  return Promise.resolve(true);
                }
                return Promise.reject(new Error("请输入正确的手机号码"));
              },
            },
          ]}
        >
          <Input placeholder="请输入顾客联系电话" />
        </Form.Item>
        <Form.Item
          isLink
          name="department"
          label="建议部门"
          trigger="onConfirm"
          onClick={(_, action) => {
            action.current?.open();
          }}
          rules={[{ required: true, message: "建议部门不能为空" }]}
        >
          <Picker popup columns={columns}>
            {(val) =>
              columns.filter((item) => item.value === val)[0]?.text ||
              "请选择建议部门"
            }
          </Picker>
        </Form.Item>
        <Form.Item
          isLink
          name="type"
          label="建议类型"
          trigger="onConfirm"
          onClick={(_, action) => {
            action.current?.open();
          }}
          rules={[{ required: true, message: "建议类型不能为空" }]}
        >
          <Picker popup columns={["商品质量", "服务态度", "商品价格", "环境卫生", "设备问题", "其他建议"]}>
            {(val) => val || "请选择建议类型"}
          </Picker>
        </Form.Item>
        <Form.Item
          name="img"
          label="建议图片"
          rules={[{ required: true, message: "请选择图片" }]}
        >
          <Uploader upload={async (file) => {
              const res = await axios.post(
                  `http://hhcs0477.com:89/index.php?s=upload-img`,
                  {
                      img: file,
                  },
                  {
                      headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization: localStorage.getItem("token"),
                      },
                  }
              );
              return res.data.data
          }}/>
        </Form.Item>
        <Form.Item
          name="content"
          label="详细说明"
          rules={[{ required: true, message: "请填写详细说明" }]}
        >
          <Input.TextArea rows={2} autoSize />
        </Form.Item>
      </Form>
    </div>
  );
};
export default FormPage;
