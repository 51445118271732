import { useEffect,useState } from "react";
import "./App.css";
import FormPage from "./pages/formPage";
import ListPage from "./pages/listPage";
import MyPage from "./pages/myPage";
import axios from "axios";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Tabbar } from "react-vant";
import { Edit, Contact, CommentO } from "@react-vant/icons";
function App() {
  const [value, setValue] = useState(1);
  const getQueryParams = () => {
    let addr = new URL(window.location.href);
    let code = addr.searchParams.get("code");
    if (code) {
      return code;
    } else {
      return "";
    }
  };
  const fetchUser = (data) => {
    return axios.get(
      `http://hhcs0477.com:89/index.php?s=super-user`,
      {
        headers: {
          Authorization: data,
        },
      }
    );
  };
  useEffect(() => {
    if(window.location.href.indexOf('list')>-1){
      setValue(1)
    }else if(window.location.href.indexOf('form')>-1){
      setValue(0)
    }else if(window.location.href.indexOf('home')>-1){
      setValue(2)
    }else{
      setValue(1)
    }
    fetchUser(localStorage.getItem("token")).then((res) => {
      if (res.data.errno !== 0) {
        let code = getQueryParams();
        if (!code) {
          getCode();
        } else {
          axios
            .get(
              "http://hhcs0477.com:89/index.php?s=super-login",
              {
                params: { code: code },
              }
            )
            .then((res) => {
              if (res.data.errno === 0) {
                // alert(JSON.stringify(res));
                localStorage.setItem("token", res.data.data.access_token);
                // navigate('/form')
                fetchUser(res.data.data.access_token).then((res) => {
                  const imgUrl = res.data.data.user.headimgurl;
                  const nickName = res.data.data.user.nickname;
                  localStorage.setItem(
                    "user",
                    JSON.stringify({
                      imgUrl: imgUrl,
                      nickName: nickName,
                    })
                  );
                  window.location.reload()
                });
              } else {
              }
            });
        }
      } else {
        const imgUrl = res.data.data.user.headimgurl;
        const nickName = res.data.data.user.nickname;
        localStorage.setItem(
          "user",
          JSON.stringify({
            imgUrl: imgUrl,
            nickName: nickName,
          })
        );
      }
    });
  }, []);

  const getCode = () => {
    // 非静默授权，第一次有弹框
    var local = "http://hhcs0477.com/WCCCP/form";
    var appid = "wx29ac1ab9864268c7"; //公众号里有自己查，不会找的查一下百度
    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      appid +
      "&redirect_uri=" +
      encodeURIComponent(local) +
      "&response_type=code&scope=snsapi_base#wechat_redirect";
    let url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      appid +
      "&redirect_uri=" +
      encodeURIComponent(local) +
      "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
    window.location.href = url;
  };
  const getUrlCode = () => {
    // 截取url中的code方法
    var url = window.location.search;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
    }
    return theRequest;
  };
  const navigate = useNavigate();
  const handleTabChange = (e) => {
    if (e === 0) {
      setValue(0)
      navigate("/form");
    } else if (e === 1) {
      setValue(1)
      navigate("/list");
    } else if (e === 2) {
      setValue(2)
      navigate("/home");
    }else {
      setValue(1)
      navigate("/list");
    }
  };
  return (
    <div>
      <Routes>
        <Route path="/WCCCP/*" element={<ListPage />} />
        <Route path="/*" element={<ListPage />} />
        <Route path="/form" element={<FormPage />} />
        <Route path="/list" element={<ListPage />} />
        <Route path="/home" element={<MyPage />} />
      </Routes>
      <Outlet />
      <Tabbar onChange={handleTabChange} value={value}>
        <Tabbar.Item icon={<Edit />}>提意见</Tabbar.Item>
        <Tabbar.Item icon={<CommentO />}>客诉公示台</Tabbar.Item>
        <Tabbar.Item icon={<Contact />}>我的意见</Tabbar.Item>
      </Tabbar>
    </div>
  );
}

export default App;
